<template>
  <div>
    <div class="search">
      <div>
        <div class="a-inline-block">
          <label for="">关键字</label>
          <a-input v-model="key" size="large" placeholder="姓名、电话" />
        </div>
        <div class="a-inline-block">
          <label for="">技能</label>
          <a-select v-model="selSkillid" class="ant-select-lg">
            <a-select-option :value="item.id" v-for="(item, index) in skillid" :key="index">{{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="a-inline-block">
          <label for="">状态</label>
          <a-select v-model="selStatus" class="ant-select-lg">
            <a-select-option :value="1">空闲中</a-select-option>
            <a-select-option :value="2">工作中</a-select-option>
          </a-select>
        </div>
        <div class="a-inline-block">
          <label for="">性别</label>
          <a-select v-model="selSex" class="ant-select-lg">
            <a-select-option :value="1">男</a-select-option>
            <a-select-option :value="2">女</a-select-option>
          </a-select>
        </div>
        <div class="a-inline-block">
          <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
          <a-button size="large" @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <div style="text-align: right">
      <div class="a-inline-block" @click="annexModel=true">
        <img src="@/assets/imgs/tMenu4.png" alt="" /><label for="">导入</label>
      </div>
      <div class="a-inline-block" @click="excelout">
        <img src="@/assets/imgs/tMenu4.png" alt="" /><label for="">导出</label>
      </div>
      <!-- <a-button class="addBtn" size="large" type="primary" @click="addType">添加工人</a-button> -->
      <a-button class="addBtn" size="large" type="primary" style="margin-left: 15px" @click="selFollow">分配跟进人</a-button>
      <a-button class="addBtn" size="large" type="primary" @click="delAll" style="margin-left: 15px">批量删除</a-button>
    </div>
    <a-table :row-selection="rowSelection" :rowKey="(record) => record.id" :columns="columns" :data-source="data"
      class="table" :pagination="pagination">
      <span slot="num" slot-scope="text, record, index">
        {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
      </span>
      <template slot="Status" slot-scope="record">
        <!-- {{ record.status }} -->
        <!-- <a href="javascript:;" @click="showStatus(record)" v-if="record.status == '工作中'">{{ record.status }}</a> -->
        <span v-if="record.status == '工作中'"  style="color: #1890ff">{{ record.status }}</span>
        <span v-else style="color: #222">{{ record.status }}</span>
      </template>
      <template slot="Action" slot-scope="text, record">

        <a class="action" href="javascript:;" @click="showDetail(record.id)">查看</a>
        <a class="action" href="javascript:;" @click="editTask(record.id)">编辑</a>
        <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
          <a class="action del" href="javascript:;">删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal v-model="annexModel" :centered="true" :footer="null" title="导入">
      <div>
        请先下载模板，按格式修改后导入
        <a-button @click="downExl()">下载模板</a-button>
      </div>
      <div>
        <label for="annex" class="annexBtn">
          <img src="@/assets/imgs/ico_daoru.png" alt="" />
          <a-button @click="$refs.annexFile.click()">导入</a-button>
        </label>
        <input type="file" id="annex" style="display: none" ref="annexFile" @change="annexUpload($event)" />
      </div>
    </a-modal>
    <a-modal v-model="visible" :centered="true" title="修改工作时间" @ok="editStatus">
      <div class="addBox">
        <div class="add_title">请选择结束工时</div>
        <a-date-picker v-model="endTime" style="margin: 30px 0" size="large" />
      </div>
    </a-modal>
    <staff :staffFlag="staffFlag" @updateStaffFlag="updateStaffFlag"></staff>

    <a-modal v-model="showFollow" :centered="true" title="分配跟进人" @ok="setFollow">
      <div class="addBox">
        <div class="add_title" style="margin-bottom: 30px">请选择跟进人</div>
        <a-select v-model="onStaff" class="ant-select-lg" style="width: 200px">
          <a-select-option :value="item.userid" v-for="(item, index) in staff" :key="index">{{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import Staff from "../../../components/staff.vue";
import {
  requestXml,
  getDateTime,
  ajaxUrl,
  fileUpload,
} from "../../../assets/js/request";
export default {
   components: {
    Staff,
  },
  data() {
    return {
      rowSelection: {
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
          this.selectedRows = selectedRows;
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
          this.selectedRows = selectedRows;
        },
      },
      staffFlag: false,
      // onStaff: {
      //   name: "",
      //   userid: "",
      // },
      key: "",
      selSkillid: "", //搜索-技能
      selStatus: "", //搜索-技能
      columns: [
        {
          title: "序号",
          dataIndex: "num",
          width: 70,
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        { title: "姓名", width: 80, dataIndex: "name", key: "1" },
        { title: "性别", width: 80, dataIndex: "sex", key: "2" },
        // { title: '身份证号',width:150, dataIndex: 'code', key: '3'},
        { title: "电话", dataIndex: "tel", key: "4" },
        {
          title: "是否有工伤保险",
          width: 80,
          dataIndex: "insurance",
          key: "5",
        },
        { title: "保险到期时间", width: 80, dataIndex: "deadline", key: "6" },
        { title: "技能", dataIndex: "skillid", key: "7" },
        {
          title: "状态",
          width: 80,
          scopedSlots: { customRender: "Status" },
          key: "8",
        },
        // { title: '服务项目',width:100, dataIndex: 'time', key: '9' },
        { title: "职务", width: 80, dataIndex: "post", key: "10" },
        // { title: '银行卡号',width:150, dataIndex: 'card', key: '11' },
        { title: "紧急联系人", dataIndex: "contactstel", key: "12" },
        { title: "跟进人", width: 100, dataIndex: "sname", key: "13" },
        { title: "备注", dataIndex: "remarks", key: "14" },
        {
          title: "操作",
          key: "operation",
          align: "center",
          fixed: "right",
          width: 250,
          scopedSlots: { customRender: "Action" },
        },
      ],
      sex: ["", "男", "女"], //性别
      status: ["", "空闲中", "工作中"], //工作状态
      insurance: ["", "有", "无"], // 有无工伤
      data: [],
      selectedRowKeys: [],
      selectedRows: "",
      // 分页
      pagination: {
        current: 1,
        total: 100,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showQuickJumper: true,
        showTotal: total => `共 ${total} 条`, // 显示总数
        onChange: (current) => this.changePage(current), //点击当前页码
        onShowSizeChange: (current, pageSize) => {
          //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
          console.log(pageSize);
          this.showSizeChange(current, pageSize);
        },
      },
      onTemp: "",
      visible: false,
      endTime: "", //工作结束时间
      showFollow: false, //跟进人弹框
      staff: "",
      onStaff: "",
      skillid: "", //技能列表
      search: "", //搜索条件
      selSex: "", //搜索性别字段
      annexModel: false,
    };
  },
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('_tempList',JSON.stringify(this._data))
    },
    mounted(){
        if(this.fromPath == "/scrm_pc/temp/tempDetail" || this.fromPath == "/scrm_pc/temp/tempAdd") {
            var data=JSON.parse(sessionStorage.getItem('_tempList'))
            sessionStorage.removeItem('_tempList')
            for(let key in data){
                console.log(data[key],this[key])
                this[key] = data[key]
            }
        }
    this.getData();
    this.getStaff();
    this.getSkillid();
  },
  methods: {
    moment,
    // 搜索
    onSearch() {
      this.pagination.current=1;
      this.getData();
    },
    // 下载模板
    downExl() {
      window.open(
        window.location.protocol +
          "//" +
          window.location.host +
          "/uploads/工人信息导入.xls"
      );
    },
    reset() {
      this.key = "";
      this.selSkillid = null;
      this.selStatus = null;
      this.selSex = "";
      this.getData();
    },
    // 获取技能分类
    getSkillid() {
      requestXml(
        "/jieb/Temporary/skillshow",
        "POST",
        (res) => {
          this.skillid = res.list;
          // console.log(this.skillid);
          // console.log(JSON.stringify( this.skillid));
          if (this.id) {
            this.getDetail();
          }
          // console.log(this.skillid);
        },
        { page: { curpage: 1, pagesize: 999999 } }
      );
    },
    // 导入
    annexUpload(e) {
      fileUpload(e, (res) => {
        console.log(res);
        requestXml(
          "/jieb/Temporary/excelinput",
          "POST",
          (res) => {
            console.log(res);
            this.annexModel = false;
            this.$refs.annexFile.value = "";
            this.getData();
          },
          { id: res.id }
        );
      });
    },
    // 导出
    excelout() {
      window.open(
        ajaxUrl +
          "/jieb/Temporary/excelout?search=" +
          JSON.stringify(this.search)
      );
    },
    // 选择跟进人
    selFollow() {
      if (this.selectedRows.length < 1) {
        this.$message.info("请选择工人");
        return false;
      } else {
        this.staffFlag = true;
      }
    },
     // 分配完
    updateStaffFlag(data) {
      // console.log(data,"dasds")
      if (data != 1) {
        this.onStaff = data;
        this.setFollow();
      }
      this.staffFlag = false;
    },
    //设置跟进人
    setFollow() {
      if (!this.onStaff) {
        this.$message.info("请选择跟进人");
        return false;
      } else {
        let idArr = [];
        let sel = this.selectedRows;
        for (let i = 0; i < sel.length; i++) {
          idArr.push(sel[i].id);
        }
        requestXml(
          "/jieb/Temporary/followup",
          "POST",
          (res) => {
            if (res == "success") {
              // this.$message.success('分配成功');
              this.showFollow = false;
              this.onStaff = "";
              this.getData();
            }
          },
          { id: idArr, userid: this.onStaff.userid }
        );
      }
    },
    // 获取成员
    getStaff() {
      requestXml("/scrm/Staff/getMinList", "POST", (res) => {
        this.staff = res;
      });
    },
    showStatus(res) {
      this.onTemp = res;
      this.visible = true;
    },
    hideFollow() {
      this.onTemp = "";
      this.onStaff = false;
      this.visible = false;
    },
    editStatus() {
      let endTime = Math.round(moment(this.endTime).endOf("day") / 1000) - 1;
      requestXml(
        "/jieb/Temporary/status",
        "POST",
        (res) => {
          console.log(res);
          this.hideFollow();
          this.getData();
        },
        { id: this.onTemp.id, time: endTime }
      );
    },
    getData() {
      requestXml(
        "/jieb/Temporary/tempbaseshow",
        "POST",
        (res) => {
          console.log(res);
          for (let i = 0; i < res.list.length; i++) {
            res.list[i].sex = this.sex[Number(res.list[i].sex)];
            res.list[i].status = this.status[Number(res.list[i].status)];
            res.list[i].insurance =
              this.insurance[Number(res.list[i].insurance)];
            res.list[i].deadline = getDateTime(res.list[i].deadline);
            if (res.list[i].deadline == "1970-01-01") {
              res.list[i].deadline = "";
            }
          }
          this.pagination.total = Number(res.page.total);
          this.pagination.pageSize = Number(res.page.pagesize);
          this.data = res.list;
          this.search = res.search;
        },
        {
          key: this.key,
          sex: this.selSex,
          skillid: this.selSkillid,
          status: this.selStatus,
          page: {
            curpage: this.pagination.current,
            pagesize: this.pagination.pageSize,
          },
        }
      );
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    // 分页
    changePage(current) {
      //current参数表示是点击当前的页码，
      console.log(current);
      this.pagination.current = current;
      this.getData(); //向后端发送请求
    },
    showSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getData(); //向后端发送请求
    },
    // 查看
    showDetail(id) {
      this.$router.push({
        path: "/scrm_pc/temp/tempDetail",
        query: {
          id: id,
        },
      });
    },
    //添加工人
    addType() {
      this.$router.push({
        path: "/scrm_pc/temp/tempAdd",
      });
    },
    // 编辑
    editTask(id) {
      this.$router.push({
        path: "/scrm_pc/temp/tempAdd",
        query: {
          id: id,
        },
      });
    },
    // // 关闭
    // off(id){
    //     requestXml("/scrm/Task/setClose","POST",(res) => {
    //         console.log(res)
    //         this.getData();
    //     },{"id": id})
    // },
    // 删除
    onDelete(id) {
      requestXml(
        "/jieb/Temporary/tempbasedel",
        "POST",
        (res) => {
          console.log(res);
          this.getData();
        },
        { id: id }
      );
    },
    delAll(){
          if(this.selectedRows.length<1){
          this.$message.info('请先选择');
          return false;
      }else{
          let idArr=[];
          let sel=this.selectedRows;
          for(let i=0;i<sel.length;i++){
              idArr.push(sel[i].id);
          }
          this.onDelete(idArr);
      }
    },
  },
};
</script>
<style scoped>
.search {
  display: flex;
  justify-content: space-between;
}
.addBtn {
  width: auto;
}
.action {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background: #dceeff;
  border: 1px solid #36a3ff;
  color: #36a3ff;
  border-radius: 5px;
}
.del {
  background: #ffdcdc;
  border-color: #ff4c4c;
  color: #ff4c4c;
}
.annexBtn {
  display: block;
  margin: 20px auto;
  width: 200px;
  text-align: center;
}
.annexBtn img {
  display: block;
  width: 100px;
  margin: 10px auto;
}
</style>

